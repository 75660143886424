
//define(function (require) {
//    require(['jquery', 'bootstrap/alert'], function() {
//        $('.alert').alert();
//    });
//});

function addDelayMessage(selector) {

    var div = $('<div class="downloader"><i class="glyphicon glyphicon-refresh spinning"></i></div>')
    $(selector).append(div);

}

function removeDelayMessage(selector) {
    $(selector+' div.downloader').html('').remove();
}
/* global  d3 */
/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


if (!DI)
    var DI = {};

/**
 * defines a namespace. Example: <code>DI.ns('DI.Carto')</code>
 * @method ns
 * @returns {Object} the ordered Namespace
 * @static
 * @param ns, a string path representing the ns, ex "com.test.www"
 */
/**
 * 
 * @param {type} ns
 * @returns {DI.ns.lastNs|DI.ns.currentNs}
 */
DI.ns = function (ns) {
    var nsPath = ns.split('.'),
            i = 0,
            l = nsPath.length,
            currentNs = window,
            lastNs;
    //TODO: test validité nom de variable
    for (; i < l; i++) {
        lastNs = currentNs[nsPath[i]];
        if (!lastNs) {
            lastNs = {
                name: nsPath[i],
                path: nsPath.slice(0, i + 1).join('.')
            }; // TODO class IScope.Ns ?
            currentNs[nsPath[i]] = lastNs;
        }
        currentNs = lastNs;
    }
    return lastNs;
};

DI.ns("DI.Carto");


DI.Carto = (function () {
    var carto = function (parameters) {
        this.id = parameters.id;
        this.width = parameters.width;
        this.height = parameters.height;
        this.offset = parameters.offset;
        this.image_src = parameters.image_src;
    };
    var instance = null;
    carto.prototype = {
        i: 0,
        root: null,
        tree: null,
        diagonal: null,
        vis: null,
        svgContainer: null,
        svg1: null,
        svg2: null,
        svgLinkContainer: null,
        point_center2: null,
        radius1: null,
        radius2: null,
        radius3: null,
        c1: null,
        c2: null,
        c3: null,
        point_tg: {},
        point_center: {},
        point_center1: {},
        point_center3: {},
        textDecal: 16,
        selected_id: 2,
        node_loaded: false,
        jsonCircles: [],
        image_attr: {},
        data1: null,
        dataArray: null,
        nodes1: null,
        links1: null,
        node1_positions: {},
        node1_coords: {},
        node2_coords: {},
        left: {},
        right: {},
        rotationFinished: true,
        intervalId: null,
        initialize: function (params) {
            var
                    w = instance.width - instance.offset.right - instance.offset.left,
                    h = instance.height - instance.offset.top - instance.offset.bottom;
            $(instance.id).children().remove();
            instance.jsonCircles = [];
            instance.tree = d3.layout.tree().size([h, w]);
            instance.diagonal = d3.svg.diagonal()
                    .projection(function (d) {
                        return [d.y, d.x];
                    });
            instance.vis = d3.select(instance.id).append("svg:svg")
                    .attr("width", instance.width)
                    .attr("height", instance.height);
            instance.svgContainer = instance.vis
                    .append("svg:g")
                    .attr("transform", "translate(" + instance.offset.left + "," + instance.offset.top + ")");
            instance.svgLinkContainer = instance.svgContainer.insert("svg:g", ":first-child").attr('id', 'links').attr('transform', 'translate(0,0)');
            instance.point_center2 = {};
            instance.point_center2.x = w / 2;
            instance.point_center2.y = h / 2;
            instance.radius2 = h / 2;
            // c2 grand
            instance.c2 = {
                x: instance.point_center2.x,
                y: instance.point_center2.y,
                r: instance.radius2,
                color: "grey"};
            instance.point_tg = {};
            instance.point_tg.x = (instance.point_center2.x - instance.radius2 * (1 / Math.sqrt(2)));
            instance.point_tg.y = (instance.point_center2.y + instance.radius2 * (1 / Math.sqrt(2)));
            instance.point_center = {};
            instance.point_center.x = (instance.point_tg.x + instance.point_center2.x) / 2;
            instance.point_center.y = (instance.point_tg.y + instance.point_center2.y) / 2;
            instance.radius1 = instance.radius2 - 20;
            instance.radius3 = instance.radius1 - 20;
            instance.point_center1 = {};
            instance.point_center1.x = (instance.point_center.x + instance.c2.x) / 2;
            instance.point_center1.y = (instance.point_center.y + instance.c2.y) / 2;
            instance.point_center3 = {};
            instance.point_center3.x = instance.point_tg.x + instance.radius3 * (1 / Math.sqrt(2));
            instance.point_center3.y = instance.point_tg.y - instance.radius3 * (1 / Math.sqrt(2));
            // moyen
            instance.c1 = {
                x: instance.point_center1.x,
                y: instance.point_center1.y,
                r: instance.radius1,
                stroke: "#BBBBBB",
                fill: '#F8F8F8'
            };
            // grand
            instance.c2 = {
                x: instance.point_center2.x,
                y: instance.point_center2.y,
                r: instance.radius2,
                stroke: "#BBBBBB",
                fill: '#EEEEEE'};
            // petit
            instance.c3 = {
                x: instance.point_center3.x,
                y: instance.point_center3.y,
                r: instance.radius3,
                stroke: "#BBBBBB",
                fill: '#F8F8F8'};
            if (instance.node_loaded) {
                instance.jsonCircles[instance.jsonCircles.length] = {
                    id: "c3", rel: "c3",
                    x_axis: instance.c3.x, y_axis: instance.c3.y,
                    radius: instance.c3.r, stroke: instance.c3.stroke, fill: instance.c3.fill};
                instance.jsonCircles[instance.jsonCircles.length] = {
                    id: "c2", rel: "c2",
                    x_axis: instance.c2.x, y_axis: instance.c2.y,
                    radius: instance.c2.r, stroke: instance.c2.stroke, fill: instance.c2.fill};
            } else {
                instance.jsonCircles[instance.jsonCircles.length] = {
                    id: "c3", rel: "c1",
                    x_axis: instance.c1.x, y_axis: instance.c1.y,
                    radius: instance.c1.r, stroke: instance.c1.stroke, fill: instance.c1.fill};
                instance.jsonCircles[instance.jsonCircles.length] = {
                    id: "c2", rel: "c1",
                    x_axis: instance.c1.x, y_axis: instance.c1.y,
                    radius: instance.c1.r, stroke: instance.c1.stroke, fill: instance.c1.fill};
            }


            instance.image_attr = {};
            instance.image_attr.w = 164;
            instance.image_attr.h = 128;
            instance.image_attr.x = instance.c1.x - (instance.image_attr.w / 2);
            instance.image_attr.y = instance.c1.y - (instance.image_attr.h / 2);
            instance.image_attr.src = instance.image_src;
            instance.svgContainer.append('svg:image')
                    .attr("x", instance.image_attr.x).attr("y", instance.image_attr.y)
                    .attr("width", instance.image_attr.w).attr("height", instance.image_attr.h)
                    .attr("xlink:href", instance.image_attr.src)
                    .attr("style", "cursor:pointer")
                    .on("click", function () {
                        //$('#s_visu').focus();
                    });
            var circles = instance.svgContainer.selectAll(".circle")
                    .data(instance.jsonCircles)
                    .enter()
                    //.append("circle")
                    .insert("svg:circle", ":first-child")
                    .attr("id", function (d) {
                        return d.id;
                    })
                    .attr("class", "circle")
                    ;
            var circleAttributes = circles
                    .attr("id", function (d) {
                        return d.id;
                    })
                    .attr("cx", function (d) {
                        return d.x_axis;
                    })
                    .attr("cy", function (d) {
                        return d.y_axis;
                    })
                    .attr("r", function (d) {
                        return d.radius;
                    })
                    .attr("rel", function (d) {
                        return d.rel;
                    })
                    .style("fill", function (d) {
                        return d.fill;
                    })
                    //.style("stroke-width", function(d) { return 1; })
                    .style("stroke", function (d) {
                        return d.stroke;
                    });
            instance.svg1 = instance.svgContainer
                    .append("svg:g")
                    .attr("transform", "translate(" + instance.c1.x + "," + instance.c1.y + ")");
            instance.svg2 = instance.svgContainer
                    .append("svg:g")
                    .attr('id', 'svg2')
                    .attr("transform", "translate(" + instance.c2.x + "," + instance.c2.y + ")");
        },
        goRight: function () {
            var turn = 1;
            if (arguments.length > 0)
                turn = arguments[0];
            var duration = (turn > 0) ? 400 / turn : 400;
            var new_node1_positions = {};
            for (var i = 1; i < instance.nodes1.length; i++) {
                var node = instance.nodes1[i];
                var id = "node1_" + node.id;
                var position = instance.node1_positions[instance.right[id]];
                new_node1_positions[id] = position;
                d3.select("#" + id).classed('selected', function (d) {
                    return ((d.id === instance.selected_id) && instance.node_loaded);
                });
                d3.select("#" + id).transition().duration(duration)
                        .attr("transform", function (d) {
                            var decalage = 90 + 22.5;
                            var r = position.y, a = (position.x - decalage) / 180 * Math.PI;
                            var x = r * Math.cos(a);
                            var y = r * Math.sin(a);
                            instance.node1_coords['node1_' + d.id] = {x: x, y: y};
                            return "translate(" + x + "," + y + ")";
                        });
            }
            instance.node1_positions = new_node1_positions;
            if (turn > 1) {
                setTimeout(function () {
                    instance.goRight(turn - 1);
                }, duration);
            } else {
                instance.rotationFinished = true;
            }
        },
        goLeft: function () {
            var turn = 1;
            if (arguments.length > 0)
                turn = arguments[0];
            var duration = (turn > 0) ? 400 / turn : 400;
            var new_node1_positions = {};
            for (var i = 1; i < instance.nodes1.length; i++) {
                var node = instance.nodes1[i];
                var id = "node1_" + node.id;
                var position = instance.node1_positions[instance.left[id]];
                new_node1_positions[id] = position;
                d3.select("#" + id).classed('selected', function (d) {
                    return ((d.id === instance.selected_id) && instance.node_loaded);
                });
                d3.select("#" + id).transition().duration(duration)
                        .attr("transform", function (d) {
                            var decalage = 90 + 22.5;
                            var r = position.y, a = (position.x - decalage) / 180 * Math.PI;
                            var x = r * Math.cos(a);
                            var y = r * Math.sin(a);
                            instance.node1_coords['node1_' + d.id] = {x: x, y: y};
                            return "translate(" + x + "," + y + ")";
                        });
            }
            instance.node1_positions = new_node1_positions;
            if (turn > 1) {
                setTimeout(function () {
                    instance.goLeft(turn - 1);
                }, duration);
            } else {
                instance.rotationFinished = true;
            }
        },
        updateCircles: function (load) {
            if (load) {
                d3.selectAll(".node1").classed('selected', function (d) {
                    return ((d.id === instance.selected_id) && instance.node_loaded);
                });
                if (d3.select('#c3').attr('rel') === "c1") {
                    d3.select("#c3").transition().duration(500)
                            .attr("rel", "c3")
                            .attr('r', instance.c3.r)
                            .attr('cx', instance.c3.x).attr('cy', instance.c3.y)
                            .style('fill', instance.c3.fill)
                            .style('stroke', instance.c3.stroke);
                    d3.select("#c2").transition().duration(500)
                            .attr("rel", "c2")
                            .attr('r', instance.c2.r)
                            .attr('cx', instance.c2.x).attr('cy', instance.c2.y)
                            .style('fill', instance.c2.fill)
                            .style('stroke', instance.c2.stroke);
                    // change position of node1
                    var tree1 = d3.layout.tree()
                            .size([360, instance.c3.r])
                            .separation(function (a, b) {
                                return (a.parent === b.parent ? 1 : 2) / a.depth;
                            });
                    instance.nodes1 = tree1.nodes(instance.data1);
                    instance.links1 = tree1.links(instance.nodes1);
                    var l = instance.nodes1.length;
                    instance.node1_positions = {};
                    instance.left = {};
                    instance.right = {};
                    for (var i = 1; i < l; i++) {
                        var node = instance.nodes1[i];
                        instance.node1_positions['node1_' + node.id] = {x: node.x, y: node.y};
                        var j = i + 1;
                        if (j === l)
                            j = 1;
                        var n_node = instance.nodes1[j];
                        instance.right["node1_" + node.id] = "node1_" + n_node.id;
                        j = i - 1;
                        if (j === 0)
                            j = l - 1;
                        n_node = instance.nodes1[j];
                        instance.left["node1_" + node.id] = "node1_" + n_node.id;
                    }
                    instance.svg1.attr("transform", "translate(" + instance.c3.x + "," + instance.c3.y + ")");
                    var node1 = instance.svg1.selectAll(".nodec1, .node1")
                            .data(instance.nodes1, function (d) {
                                return d.id;
                            });
                    var duration = 500;
                    // Transition updating nodes positions.
                    var nodeUpdate = node1.transition()
                            .duration(duration)
                            .attr("transform", function (d) {
                                var decalage = 90 + 22.5;
                                var r = d.y, a = (d.x - decalage) / 180 * Math.PI;
                                var x = r * Math.cos(a);
                                var y = r * Math.sin(a);
                                instance.node1_coords['node1_' + d.id] = {x: x, y: y};
                                return "translate(" + x + "," + y + ")";
                            });
                }
            } else {
                d3.select("#c3").transition().duration(500)
                        .attr('rel', "c1").attr('r', instance.c1.r)
                        .attr('cx', instance.c1.x).attr('cy', instance.c1.y)
                        .style('fill', instance.c1.fill).style('stroke', instance.c1.stroke);
                d3.select("#c2").transition().duration(500)
                        .attr('rel', "c1").attr('r', instance.c1.r)
                        .attr('cx', instance.c1.x).attr('cy', instance.c1.y)
                        .style('fill', instance.c1.fill).style('stroke', instance.c1.stroke);
                // change position of node1
                var tree1 = d3.layout.tree()
                        .size([360, instance.c1.r])
                        .separation(function (a, b) {
                            return (a.parent === b.parent ? 1 : 2) / a.depth;
                        });
                instance.nodes1 = tree1.nodes(instance.data1);
                instance.links1 = tree1.links(instance.nodes1);
                var l = instance.nodes1.length;
                instance.node1_positions = {};
                instance.left = {};
                instance.right = {};
                for (var i = 1; i < l; i++) {
                    var node = instance.nodes1[i];
                    instance.node1_positions['node1_' + node.id] = {x: node.x, y: node.y};
                    var j = i + 1;
                    if (j === l)
                        j = 1;
                    var n_node = instance.nodes1[j];
                    instance.right["node1_" + node.id] = "node1_" + n_node.id;
                    j = i - 1;
                    if (j === 0)
                        j = l - 1;
                    n_node = instance.nodes1[j];
                    instance.left["node1_" + node.id] = "node1_" + n_node.id;
                }
                instance.svg1.attr("transform", "translate(" + instance.c1.x + "," + instance.c1.y + ")");
                var node1 = instance.svg1.selectAll(".nodec1, .node1")
                        .data(instance.nodes1, function (d) {
                            return d.id;
                        });
                var duration = 500;
                // Transition updating nodes positions.
                var nodeUpdate = node1.transition()
                        .duration(duration)
                        .attr("transform", function (d) {
                            var decalage = 90 + 22.5;
                            var r = d.y, a = (d.x - decalage) / 180 * Math.PI;
                            var x = r * Math.cos(a);
                            var y = r * Math.sin(a);
                            instance.node1_coords['node1_' + d.id] = {x: x, y: y};
                            return "translate(" + x + "," + y + ")";
                        });
                instance.selected_id = 2;
                d3.selectAll(".node1").classed('selected', function (d) {
                    return ((d.id === instance.selected_id) && instance.node_loaded);
                });
            }
        },
        overNode: function () {
            var node_id = d3.select(this).attr('id');
            var id = parseInt(node_id.substring(6));
            d3.select('#node1_' + id).classed('over', true);
        },
        outNode: function () {
            var node_id = d3.select(this).attr('id');
            var id = parseInt(node_id.substring(6));
            d3.select('#node1_' + id).classed('over', false);
        },
        selectNode: function () {
            var node_id = d3.select(this).attr('id');
            d3.select(this).classed('over', false);
            var id = parseInt(node_id.substring(6));
            if (instance.selected_id === id && instance.node_loaded) {
                instance.deleteNodes();
                instance.node_loaded = false;
                instance.updateCircles(instance.node_loaded);
            } else {
                var e = ((id === instance.selected_id) && instance.node_loaded);
                var diff = (8 + id - instance.selected_id) % 8;
                instance.deleteNodes();
                instance.node_loaded = true;
                instance.updateCircles(instance.node_loaded);
                setTimeout(function () {
                    instance.selected_id = id;
                    if (diff > 4) {
                        instance.rotationFinished = false;
                        instance.goRight(8 - diff);
                    } else if (diff <= 4 && diff !== 0) {
                        instance.rotationFinished = false;
                        instance.goLeft(diff);
                    }
                }, 500);
                instance.intervalId = setInterval(function () {
                    if (instance.rotationFinished) {
                        clearInterval(instance.intervalId);
                        instance.loadNodes();
                    }
                    ;
                }, 500);
            }
        },
        overTag: function () {
            var node_id = d3.select(this).attr('id');
            var id = parseInt(node_id.substring(6));
            d3.select('#node2_' + id).classed('over', true);
        },
        outTag: function () {
            var node_id = d3.select(this).attr('id');
            var id = parseInt(node_id.substring(6));
            d3.select('#node2_' + id).classed('over', false);
        },
        createTagSnippet: function (type, name, id) {
            var li = $('<li class="' + type + '"><span><span>' + name + '</span>&nbsp;<a title="supprimer" data-name="' + name + '" data-type="' + type + '" data-id="' + id + '" id="tag_' + id + '" class="tag-remover" href="#">x</a></span></li>');
            li.appendTo('#tags .tagbox ul');
            $('#a_' + id).addClass('active');
            d3.select('#node2_' + id).classed('selected', true);
            instance.selected2[id] = {name: name, type: type, id: id};
            $('#tags .tagbox span.tag-title').show();
        },
        createTagRemover: function (tag) {
            instance.createTagSnippet(tag.type, tag.name, tag.id);
            data = {
                name: 'tag',
                type: tag.type,
                label: tag.name,
                value: tag.id,
                action: 'filtre',
                op: 'add'};
            addDelayMessage("#articles-wrapper");
            getArticles(data);
            removeDelayMessage("#articles-wrapper");
            //$.post('resultats_taconite.php?page=1', {provenance: 'filtre', operation: 'addTag', name: tag.type, value: tag.id});
        },
        deleteTagRemover: function (tag) {
            $('a#tag_' + tag.id).parent().parent().html('').remove();
            $('#a_' + tag.id).removeClass('active');
            delete instance.selected2[tag.id];
            d3.select('#node2_' + tag.id).classed('selected', false);
            if ($('#tags .tagbox ul li').length === 0) {
                $('#tags .tagbox span.tag-title').hide();
            }
            data = {
                name: 'tag',
                type: tag.type,
                label: tag.name,
                value: tag.id,
                action: 'filtre',
                op: 'remove'};
            addDelayMessage("#articles-wrapper");
            getArticles(data);
            removeDelayMessage("#articles-wrapper");
            //$.post('resultats_taconite.php?page=1', {provenance: 'filtre', operation: 'deleteTag', name: tag.type, value: tag.id});
        },
        selected2: {},
        selectTag: function () {
            var node_id = d3.select(this).attr('id');
            var node_name = d3.select(this).attr('name');
            var node_type = d3.select(this).attr('type');
            d3.select(this).classed('over', false);
            var id = parseInt(node_id.substring(6));
            if (node_name === 'Plus...') {
                //console.log('Plus clique ' + node_type);
                $('#modal-' + node_type).modal('show');
            } else {
                if (d3.select(this).classed('selected')) {
                    instance.deleteTagRemover({id: id, type: node_type, name: node_name});
                } else {
                    instance.createTagRemover({id: id, type: node_type, name: node_name});
                }
            }
        },
        loadGroupNodes: function () {
            var tree1 = d3.layout.tree()
                    .size([360, instance.c1.r])
                    .separation(function (a, b) {
                        return (a.parent === b.parent ? 1 : 2) / a.depth;
                    });
            instance.nodes1 = tree1.nodes(instance.data1);
            instance.links1 = tree1.links(instance.nodes1);
            var l = instance.nodes1.length;
            for (var i = 1; i < l; i++) {
                var node = instance.nodes1[i];
                instance.node1_positions['node1_' + node.id] = {x: node.x, y: node.y};
                var j = i + 1;
                if (j === l)
                    j = 1;
                var n_node = instance.nodes1[j];
                instance.right["node1_" + node.id] = "node1_" + n_node.id;
                j = i - 1;
                if (j === 0)
                    j = l - 1;
                n_node = instance.nodes1[j];
                instance.left["node1_" + node.id] = "node1_" + n_node.id;
            }
            var node1 = instance.svg1.selectAll(".nodec1, .node1")
                    .data(instance.nodes1, function (d) {
                        return d.id;
                    })
                    .enter().append("g")
                    .attr("id", function (d) {
                        return "node1_" + d.id;
                    })
                    .attr("class", function (d) {
                        return d.class + ' ' + d.type;
                    })
                    .classed('selected', function (d) {
                        return ((d.id === instance.selected_id) && instance.node_loaded);
                    })
                    .on("mouseover", instance.overNode)
                    .on("mouseout", instance.outNode)
                    .on("click", instance.selectNode);
            node1
                    .append("circle")
                    .attr("r", 10);
            node1
                    .append("text")
                    .attr("dy", ".31em")
                    .attr("text-anchor", "end")
                    .attr("transform", "translate(-" + instance.textDecal + ")")
                    .text(function (d) {
                        return d.value !== undefined ? d.name + ' (' + d.value + ')' : d.name;
                    });


            node1.attr("transform", function (d) {
                var decalage = 90 + 22.5;
                var r = d.y, a = (d.x - decalage) / 180 * Math.PI;
                var x = r * Math.cos(a);
                var y = r * Math.sin(a);
                instance.node1_coords['node1_' + d.id] = {x: x, y: y};
                return "translate(" + x + "," + y + ")";
            });
        },
        nodes2: null,
        links2: null,
        loadNodes: function () {
            var diagonal = d3.svg.diagonal.radial();
            instance.node_loaded = true;
            var duration = 1000;

            var data2 = instance.dataArray[instance.selected_id];
            var angle = (instance.dataArray[instance.selected_id].children.length) * 6;
            angleDecal = (angle - 6) / 2 + 3;
            var angleDecal = 29 + (instance.dataArray[instance.selected_id].children.length - 1) * 3;
            if (angleDecal > 60) {
                angleDecal = 60;
            }
            var tree2 = d3.layout.tree()
                    .size([angle, instance.c2.r])
                    .separation(function (a, b) {
                        return (a.parent === b.parent ? 1 : 2) / a.depth;
                    });

            instance.nodes2 = tree2.nodes(data2);
            instance.links2 = tree2.links(instance.nodes2);

            var node2 = instance.svg2.selectAll(".nodec2, .node2")
                    .data(instance.nodes2, function (d) {
                        return d.id;
                    })
                    // .data(nodes2)
                    .enter().append("g")
                    .attr("id", function (d) {
                        return "node2_" + d.id;
                    })
                    .attr("name", function (d) {
                        return "" + d.name;
                    })
                    .attr("type", function (d) {
                        return "" + d.type;
                    })
                    .attr("class", function (d) {
                        if (typeof instance.selected2[d.id] === 'undefined') {
                            return d.class + ' ' + d.type;
                        } else {
                            return d.class + ' ' + d.type + ' selected';
                        }
                    })
                    .on("mouseover", instance.overTag)
                    .on("mouseout", instance.outTag)
                    .on("click", instance.selectTag);
            node2
                    .append("rect")
                    .attr('x', -2).attr('y', -2).attr('width', 10).attr('height', 4);


            node2
                    .append("text")
                    .attr("dy", ".31em")
                    .attr("text-anchor", "start")
                    .attr("transform", "translate(" + instance.textDecal + ")")
                    .text(function (d) {
                        return (d.value !== undefined && d.value !== '') ? d.name + ' (' + d.value + ')' : d.name;
                    });


            // open node2
            var source = {};
            node2.select("text").style("fill-opacity", 1e-6);
            node2.select("circle").attr("r", 1e-6);
            var transition = node2
                    .attr("transform", function (d) {
                        source.x0 = instance.c3.x - instance.c2.x + instance.node1_coords['node1_' + instance.selected_id].x;
                        source.y0 = instance.c3.y - instance.c2.y + instance.node1_coords['node1_' + instance.selected_id].y;
                        return "translate(" + source.x0 + "," + source.y0 + ")";
                    })
                    .transition()
                    //.delay(500)
                    .duration(duration);
            transition.attr("transform", function (d) {
                if (isNaN(d.x))
                    d.x = 0;
                var r = d.y, a = (d.x - angleDecal) / 180 * Math.PI;
                var x = r * Math.cos(a), y = r * Math.sin(a);
                instance.node2_coords['node2_' + d.id] = {x: x, y: y};
                return "translate(" + x + "," + y + ")";
            });
            transition.select("text").style("fill-opacity", 1);
            transition.select("circle").attr("r", 10);

            d3.select("#c3").transition().duration(500)
                    .attr("rel", "c3").attr('r', instance.c3.r)
                    .attr('cx', instance.c3.x).attr('cy', instance.c3.y)
                    .style('fill', instance.c3.fill).style('stroke', instance.c3.stroke);
            d3.select("#c2").transition().duration(500)
                    .attr("rel", "c2").attr('r', instance.c2.r)
                    .attr('cx', instance.c2.x).attr('cy', instance.c2.y)
                    .style('fill', instance.c2.fill).style('stroke', instance.c2.stroke);



            links = [];
            source = {};
            var node1_coord = instance.node1_coords['node1_' + instance.selected_id];
            var node2_coord;
            source.x = node1_coord.x + instance.c3.x;
            source.y = node1_coord.y + instance.c3.y;
            var first = true;
            for (var i in instance.node2_coords) {
                node2_coord = instance.node2_coords[i];
                if (!first) {
                    var target = {};
                    target.x = source.x;
                    target.y = source.y;
                    target.id = i;
                    links[links.length] = {source: source, target: target};
                }
                first = false;
            }
            var link = instance.svgLinkContainer.selectAll("path.link")
                    .data(links, function (d) {
                        return d.target.id;
                    });
            link.enter().append("path")
                    .attr("class", "link")
                    .attr("d", diagonal);

            links = [];
            first = true;
            for (var i in instance.node2_coords) {
                node2_coord = instance.node2_coords[i];
                if (!first) {
                    var target = {};
                    target.x = node2_coord.x + instance.c2.x;
                    target.y = node2_coord.y + instance.c2.y;
                    target.id = i;
                    links[links.length] = {source: source, target: target};
                } else {
                    first = false;
                }
            }
            link = instance.svgLinkContainer.selectAll("path.link")
                    .data(links, function (d) {
                        return d.target.id;
                    });
            link.transition().duration(duration)
                    .attr("class", "link")
                    .attr("d", diagonal);


        },
        deleteNodes: function () {
            var diagonal = d3.svg.diagonal.radial();
            if (instance.node_loaded) {
                var data2 = {};
                var angle = (instance.dataArray[instance.selected_id].children.length + 1) * 10;
                var tree2 = d3.layout.tree()
                        .size([angle, instance.c2.r])
                        .separation(function (a, b) {
                            return (a.parent === b.parent ? 1 : 2) / a.depth;
                        });
                instance.nodes2 = tree2.nodes(data2);
                //links2 = tree2.links(instance.nodes2);

                var node = instance.svg2.selectAll(".nodec2, .node2")
                        .data(instance.nodes2, function (d) {
                            return d.id;
                        });

                var source = {x: 0, y: 0};
                var duration = 500;
                // Transition exiting nodes to the parent's new position.
                var nodeExit = node.exit().transition()
                        .duration(duration)
                        .attr("transform", function (d) {
                            var x = instance.c3.x - instance.c2.x + instance.node1_coords['node1_' + instance.selected_id].x;
                            var y = instance.c3.y - instance.c2.y + instance.node1_coords['node1_' + instance.selected_id].y;
                            return "translate(" + x + "," + y + ")";
                        })
                        .remove();

                nodeExit.select("circle").attr("r", 1e-6);
                nodeExit.select("text").style("fill-opacity", 1e-6);


                links = [];
                source = {};
                node1_coord = instance.node1_coords['node1_' + instance.selected_id];
                source.x = node1_coord.x + instance.c3.x;
                source.y = node1_coord.y + instance.c3.y;
                first = true;
                for (var i in instance.node2_coords) {
                    node2_coord = instance.node2_coords[i];
                    if (!first) {
                        var target = {};
                        target.x = source.x;
                        target.y = source.y;
                        target.id = i;
                        links[links.length] = {source: source, target: target};
                    }
                    first = false;
                }
                instance.node2_coords = {};
                var link = instance.svgLinkContainer.selectAll("path.link")
                        .data(links, function (d) {
                            return d.target.id;
                        });

                link.transition().duration(duration)
                        .attr("class", "link")
                        .attr("d", diagonal)
                        .remove();

                //node2_coords = {};
            }
        },
        update: function (source) {
            var duration = d3.event && d3.event.altKey ? 5000 : 500;

            // Compute the new tree layout.
            var nodes = instance.tree.nodes(root).reverse();

            // Normalize for fixed-depth.
            nodes.forEach(function (d) {
                d.y = d.depth * 180;
            });

            // Update the nodes
            var node = instance.vis.selectAll("g.node")
                    .data(nodes, function (d) {
                        return d.id || (d.id = ++i);
                    });

            // Enter any new nodes at the parent's previous position.
            var nodeEnter = node.enter().append("svg:g")
                    .attr("class", "node")
                    .attr("transform", function (d) {
                        return "translate(" + source.y0 + "," + source.x0 + ")";
                    })
                    .on("click", function (d) {
                        instance.toggle(d);
                        instance.update(d);
                    });

            nodeEnter.append("svg:circle")
                    .attr("r", 1e-6)
                    .style("fill", function (d) {
                        return d._children ? "lightsteelblue" : "#fff";
                    });

            nodeEnter.append("svg:text")
                    .attr("x", function (d) {
                        return d.children || d._children ? -10 : 10;
                    })
                    .attr("dy", ".35em")
                    .attr("text-anchor", function (d) {
                        return d.children || d._children ? "end" : "start";
                    })
                    .text(function (d) {
                        return d.name;
                    })
                    .style("fill-opacity", 1e-6);

            // Transition nodes to their new position.
            var nodeUpdate = node.transition()
                    .duration(duration)
                    .attr("transform", function (d) {
                        return "translate(" + d.y + "," + d.x + ")";
                    });

            nodeUpdate.select("circle")
                    .attr("r", 4.5)
                    .style("fill", function (d) {
                        return d._children ? "lightsteelblue" : "#fff";
                    });

            nodeUpdate.select("text")
                    .style("fill-opacity", 1);

            // Transition exiting nodes to the parent's new position.
            var nodeExit = node.exit().transition()
                    .duration(duration)
                    .attr("transform", function (d) {
                        return "translate(" + source.y + "," + source.x + ")";
                    })
                    .remove();

            nodeExit.select("circle").attr("r", 1e-6);
            nodeExit.select("text").style("fill-opacity", 1e-6);

            // Update the links
            var link = instance.vis.selectAll("path.link")
                    .data(instance.tree.links(nodes), function (d) {
                        return d.target.id;
                    });

            // Enter any new links at the parent's previous position.
            link.enter().insert("svg:path", "g")
                    .attr("class", "link")
                    .attr("d", function (d) {
                        var o = {x: source.x0, y: source.y0};
                        return diagonal({source: o, target: o});
                    })
                    .transition()
                    .duration(duration)
                    .attr("d", diagonal);

            // Transition links to their new position.
            link.transition()
                    .duration(duration)
                    .attr("d", diagonal);

            // Transition exiting nodes to the parent's new position.
            link.exit().transition()
                    .duration(duration)
                    .attr("d", function (d) {
                        var o = {x: source.x, y: source.y};
                        return diagonal({source: o, target: o});
                    })
                    .remove();

            // Stash the old positions for transition.
            nodes.forEach(function (d) {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        },
        toggle: function (d) {
            if (d.children) {
                d._children = d.children;
                d.children = null;
            } else {
                d.children = d._children;
                d._children = null;
            }
        }

    };
    var instance = null;
    return new function () {
        this.getInstance = function () {
            if (instance === null) {
                instance = new carto({
                    id: '#carto',
                    width: 900,
                    height: 460,
                    offset: {top: 20, right: 220, bottom: 30, left: 120},
                    image_src: '/images/moteur.png'
                });
                instance.carto = null;
            }

            return instance;
        };
        this.removeInstance = function () {
            instance = null;
        };
    };
})();
/*
 carto = new DI.Carto({
 id: '#carto',
 width: 900,
 height: 460,
 offset: {top: 20, right: 220, bottom: 30, left: 120},
 image_src: '/images/moteur.png'
 });
 */


/*
 carto.data1 = data1;
 carto.dataArray = dataArray;
 carto.initialize();
 
 carto.loadGroupNodes();
 */







/*
$("body").on("click", "a.le_titre", function (e) {
    e.preventDefault();
    var article_id = $(this).attr('id');
    var article_position = $(this).attr('position');
    var article_rub = $(this).attr('rub');
    if (article_rub === 'vs' || article_rub === 'er' || article_rub === 'sit' || article_rub === 'bre' || article_rub === 'pro') {
        $('#modal').load('https://www.iar-tremplin.com/membres/doc_detail_taconite.php?id=' + article_id + '&rub=' + article_rub + '&position=' + article_position);
        $('#modal').modal('show');
    } else {
        if (article_rub === 'ag') {
            $('#modal').load('https://www.iar-tremplin.com/membres/ag_detail_taconite.php?id=' + article_id + '&rub=' + article_rub + '&position=' + article_position);
            $('#modal').modal('show');
        }
    }
});
*/
$("body").on("click", ".tag-remover", function (e) {
    e.preventDefault();
    //var tag_id = $(this).attr('id');
    //var tag_type = $(this).attr('type');
    //var id = parseInt(tag_id.substring(4));
    var tag = $(this).data();
    delete DI.Carto.getInstance().selected2[tag.id];
    d3.select('#node2_' + tag.id).classed('selected', false);
    //addDelayMessage("#articles-wrapper");
    //getArticles(data);
    //removeDelayMessage("#articles-wrapper");

    //$.post('resultats_taconite.php?page=1', {provenance: 'filtre', operation: 'deleteTag', name: tag_type, value: id});
    //$(this).parent().parent().html('').remove();
    DI.Carto.getInstance().deleteTagRemover(tag);
});
$("body").on("click", ".a-tag-remover", function (e) {
    var tag_id = $(this).attr('id');
    var id = parseInt(tag_id.substring(2));
    var name = $(this).attr('name');
    var type = $(this).attr('type');
    if (!$(this).hasClass('active')) {
        DI.Carto.getInstance().createTagRemover({id: id, type: type, name: name});
    } else {
        DI.Carto.getInstance().deleteTagRemover({id: id, type: type, name: name});
    }
    return false;
});


var Carto = DI.Carto.getInstance();

function sortValAsc(type) {
    $("div#tri_" + type + " button").removeClass('btn-info').addClass('btn-default');
    $("div#tri_" + type + " button.val_asc ").removeClass('btn-default').addClass('btn-info');
    $("ul#list_" + type).children().sortDomElements(function (a, b) {
        akey = parseInt($("a", a).attr("value"));
        bkey = parseInt($("a", b).attr("value"));
        if (akey === bkey)
            return 0;
        if (akey < bkey)
            return -1;
        if (akey > bkey)
            return 1;
    });
}

function sortValDesc(type) {
    $("div#tri_" + type + " button").removeClass('btn-info').addClass('btn-default');
    $("div#tri_" + type + " button.val_desc ").removeClass('btn-default').addClass('btn-info');
    $("ul#list_" + type).children().sortDomElements(function (a, b) {
        akey = parseInt($("a", a).attr("value"));
        bkey = parseInt($("a", b).attr("value"));
        if (akey === bkey)
            return 0;
        if (akey < bkey)
            return 1;
        if (akey > bkey)
            return -1;
    });
}

function sortNameAsc(type) {
    $("div#tri_" + type + " button").removeClass('btn-info').addClass('btn-default');
    $("div#tri_" + type + " button.name_asc ").removeClass('btn-default').addClass('btn-info');
    $("ul#list_" + type).children().sortDomElements(function (a, b) {
        akey = $("a", a).attr("name");
        bkey = $("a", b).attr("name");
        if (akey === bkey)
            return 0;
        if (akey < bkey)
            return -1;
        if (akey > bkey)
            return 1;
    });
}
jQuery.fn.sortDomElements = (function () {
    return function (comparator) {
        return Array.prototype.sort.call(this, comparator).each(function (i) {
            this.parentNode.appendChild(this);
        });
    };
})();